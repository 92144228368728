/* src/components/Footer/Footer.css */
.footer {
    background-color: #e1e1e1;
    color: white;
    padding: 20px 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-link {
    color: #1f86ff;
    text-decoration: none;
    font-weight: bold;
}

.footer-link:hover {
    text-decoration: underline;
}
