.download-button-container {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 20px;
}

.download-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #0056b3;
}

.download-button.disabled {
    background-color: #aaa;
    cursor: not-allowed;
}
